/**
 * 消息中心
 */
import React from "react";
import { connect } from "react-redux";

import {
  changePwdInitAction,
  changeUserBasicInfoAction,
  doChangePwdAction,
  doLogoutAction,
} from "@/redux-saga/account/actions";
import PB from "@/libs/simplePB";
import { Copyright } from "@/components/framework/frame.components";
import style from "@/style/containers/noticeView.less";

import StickyNodes from "@/components/common/common.stickyNodes";
import NodeTodo from "@/components/common/node/todo/common.node.todo";
import Feedback from "@/components/feedback/feedback.1.0";
import { submitFeedbackAction } from "@/redux-saga/system/actions";
import { aidDomain, SysUIConfig } from "@/constants/sys.config";
import BadgeInfo from "@/components/mainView/main.badgeInfo";
import Loading from "@/components/common/common.loading";
import TimeDisplayField from "@/components/common/common.timeDisplayField";

import { List, Empty, Pagination, message, Modal, Radio, Input } from "antd";
import { IconTypes } from "@/constants/common";
import Icon from "@/components/common/common.icon";
import { getHttpUtil,httpUtilWithNoMsg as httpUtil } from "@/utils/HttpUtil";
import moment from "moment";
import intl from 'react-intl-universal';

const httpUtil2 = getHttpUtil(false, aidDomain);

const API_GetUserNotices = ({ user_id, cate, start, end, limit, offset }) => {
  if(cate.includes('collaborate_apply')){
    return httpUtil.get(`/map_v2/msg/list`, {
      user_id,
      cate,
      start,
      end,
      limit,
      offset,
      axios: { loading_msg: "正在读取..." },
      save_read_time: 1,
    });
  }else{
    return httpUtil2.get(`/notice_api/list`, {
      user_id,
      cate,
      start,
      end,
      limit,
      offset,
      axios: { loading_msg: "正在读取..." },
      save_read_time: 1,
    });
  }
};

const API_CollaborateExamine = ({ user_id, is_agree, reason, smg_id, view_id,access_token,from_user_id }) => {
  return httpUtil.post(`/map_v2/msg/collaborate/examine`, {
    user_id,
    is_agree,
    reason,
    smg_id,
    view_id,
    access_token,
    from_user_id,
    axios: { loading_msg: "正在读取..." },
  });
};

class NoticeView extends React.PureComponent {
  state = {
    currentCateName: "我的待办",
    currentCateValue: "node_task_reminder",
    currentData: [],
    currentDataTotal: 0,
    currentPageSize: 10,
    currentPageNo: 1,
    loadingData: true,
    is_agree: 1,
    reason:''
  };

  componentDidMount() {
    document.title = "消息中心 -  "+intl.get('Custom.general.title');
    let me = this;
    this.changeCate({
      label: this.state.currentCateName,
      value: this.state.currentCateValue,
    });

    // 便签小窗口显示隐藏
    PB.sub(this, "user", "stickyNodes.show", ({ show }) => {
      me.setState({ showStickyNodesWindow: show });
    });
    // 便签小窗口内容有变化
    PB.sub(
      this,
      "user",
      "stickyNodes.hasChange",
      ({ hasSave = false, hasUpdate = false }) => {
        me.setState({ hasChangeInStickyNodes: hasSave || hasUpdate });
      }
    );
  }

  componentWillUnmount() {
    PB.remove(this);
  }

  getNoticeList = (item = {}) => {
    let me = this;
    // 从 localstorage 中取出 user id
    const userId = localStorage.getItem("userId");
    const {
      currentCateName,
      currentCateValue,
      currentPageSize,
      currentPageNo,
    } = me.state;
    const { label, value } = item;
    let currentPageNo_ = currentPageNo;
    if (currentPageNo < 1) {
      currentPageNo_ = 1;
    }
    // 请求数据
    API_GetUserNotices({
      user_id: userId,
      cate: value || currentCateValue,
      limit: currentPageSize,
      offset: (currentPageNo_ - 1) * currentPageSize,
    })
      .then((res) => {
        if (res && res.data && res.data.code === 0) {
          console.log("请求的消息数据=", res.data);
          this.setState({
            currentCateName: label || currentCateName,
            currentCateValue: value || currentCateValue,
            currentData: res.data.data,
            // currentData: [],
            currentDataTotal: res.data.total,
            // currentDataTotal: 0,
            loadingData: false,
          });
        } else {
          // 获取数据错误
          this.setState({
            currentCateName: label || currentCateName,
            currentCateValue: value || currentCateValue,
            currentData: [],
            currentDataTotal: 0,
            loadingData: false,
          });
        }
      })
      .catch((code, msg) => {
        console.error("请求的消息数据出错：", code, msg);
        this.setState({
          currentCateName: label || currentCateName,
          currentCateValue: value || currentCateValue,
          currentData: [],
          currentDataTotal: 0,
          loadingData: false,
        });
      });
  };

  changeCate = (item) => {
    let me = this;
    this.setState(
      {
        loadingData: true,
        currentPageNo: 1,
      },
      () => {
        // 请求数据
        me.getNoticeList(item);
      }
    );
  };

  changePageNo = (pageNo) => {
    let me = this;
    this.setState(
      {
        loadingData: true,
        currentPageNo: pageNo,
      },
      () => {
        // 请求数据
        me.getNoticeList();
      }
    );
  };

  changePageSize = (pageSize) => {
    let me = this;
    this.setState(
      {
        loadingData: true,
        currentPageSize: pageSize,
      },
      () => {
        // 请求数据
        me.getNoticeList();
      }
    );
  };

  collaborate_reply = (item) => {
    let me = this;
    me.setState({"is_agree" : 1,"reason" : ''},()=>{
      Modal.info({
        width: 600,
        title: '申请协作审核',
        content: (
          <span>
            <p>
            {item.msg_title}
            </p>
            <p style={{color:'darkgray'}}>
            申请内容：{item.msg_body}
            </p>
            <p>
            是否同意：
            <Radio.Group defaultValue={1} buttonStyle="solid" onChange={e => {
                    me.setState({"is_agree" : e.target.value});
                    me.forceUpdate();
                    console.error(e.target.value,e);
                  }}>
              <Radio.Button value={1}>同意</Radio.Button>
              <Radio.Button value={2}>不同意</Radio.Button>
            </Radio.Group><br/>
            </p>
            <p>
                回复/原因：
                <Input.TextArea
                  rows={5}
                  defaultValue={me.state.reason}
                  onChange={e => {
                    me.setState({"reason" : e.target.value});
                    me.forceUpdate();
                  }}
                />
                <br />
              </p>
          </span>
        ),
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          me.collaborateExamine(item);
        },
        okCancel: true,
        mask: true,
        //maskClosable: false,
      });
    });
  }

  collaborateExamine = (item) => {
    let me = this;
    const userId = parseInt(localStorage.getItem("userId"));
    const msg_data = item['msg_data'] ? JSON.parse(item['msg_data']) : null;

    // 请求数据
    API_CollaborateExamine({
      user_id: userId,
      is_agree:me.state.is_agree,
      reason:me.state.reason, 
      smg_id:item.id, 
      view_id:msg_data.view_id||'',
      access_token:msg_data.access_token||'',
      from_user_id:item.from_user_id
    })
      .then((res) => {
        if (res && res.data && res.data.code === 0) {
          console.log("请求的消息数据=", res.data);
          message.success('操作成功！');
        } else {
          message.warning('操作失败，稍后再试。' + res.data.msg);
        }
      })
      .catch((code, msg) => {
        console.error("请求的消息数据出错：", code, msg);
        message.warning('操作失败，稍后再试。');
      });
  }

  render() {
    let me = this;
    const {
      currentCateName,
      currentCateValue,
      currentData,
      currentDataTotal,
      currentPageNo,
      currentPageSize,
      loadingData,
    } = this.state;
    const cate_data = [
      {
        label: "待办通知",
        value: "node_task_reminder",
      },
      {
        label: "连接通知",
        value: "link_reminder",
      },
      {
        label: "收到的赞",
        value: "badge_reminder",
      },
      {
        label: "我的申请",
        value: "collaborate_apply",
      },
      {
        label: "收到申请",
        value: "collaborate_apply_receive",
      },
    ];
    console.log(
      "Math.ceil(currentDataTotal / currentPageSize)=",
      Math.ceil(currentDataTotal / currentPageSize)
    );
    return (
      <div className={`dark-theme ${style["frame"]}`}>
        <Loading />
        {/*消息中心*/}
        <div className={style["notice-outer"]}>
          <div className={style["notice-cate-outer"]}>
            <h3 style={{ marginBottom: 16, paddingLeft: 15 }}>
              <Icon
                name={"icon-ai-console"}
                type={IconTypes.ICON_FONT}
                style={{
                  fontSize: "1.5rem",
                  verticalAlign: "-0.25rem",
                }}
              />{" "}
              消息中心
            </h3>
            <List
              // header={<div>消息中心</div>}
              // footer={<div>Footer</div>}
              // bordered
              split={false}
              dataSource={cate_data}
              renderItem={(item, idx) => (
                <List.Item
                  key={"cate" + idx}
                  className={
                    currentCateValue === item.value
                      ? style["notice-cate-li"] + " " + style["active"]
                      : style["notice-cate-li"]
                  }
                  onClick={() => this.changeCate(item)}
                >
                  <div>● {item.label}</div>
                </List.Item>
              )}
            />
          </div>
          <div className={style["notice-list-outer"]}>
            <div className={style["notice-list-header"]}>
              <h3 style={{ marginBottom: 16, marginLeft: 20, color: "#FFF" }}>
                {currentCateName}
              </h3>
            </div>
            <div
              className={
                style["notice-list-content"] + " scrollbar-none scrollbar-18"
              }
            >
              {currentData.length > 0 ? (
                <List
                  itemLayout="horizontal"
                  dataSource={currentData}
                  locale={{ emptyText: "暂无信息" }}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <span
                              style={{
                                marginRight: "15px",
                              }}
                            >
                              <Icon
                                name={"icon-caret-right"}
                                type={IconTypes.ICON_FONT}
                                style={{
                                  color: "#1296db",
                                  verticalAlign: "0rem",
                                }}
                              />{" "}
                              {currentCateValue=='collaborate_apply'?item.msg_title.replace('用户','我') : item.notice_title||item.msg_title}
                              <span
                                style={{
                                  color: "#696c6f",
                                  marginLeft: 15,
                                }}
                              >
                                <Icon
                                  name={"clock-circle"}
                                  style={{ marginRight: "0.3rem" }}
                                />
                                <TimeDisplayField
                                  timestamp={moment(
                                    item.act_time_str,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).valueOf()}
                                />
                              </span>
                            </span>
                            {/*<Typography.Text style={{color: '#1296db'}}>[{item.act_time_str}]</Typography.Text>*/}
                          </div>
                        }
                        description={
                          item.notice_link||item.msg_link ? (
                            <div style={{ paddingLeft: 20 }}>
                              {item.notice_body||item.msg_body}
                              <div style={{ marginTop: 10 }}>
                                <a href={item.notice_link||item.msg_link} target="_blank">
                                  <Icon
                                    name={"icon-link-o"}
                                    type={IconTypes.ICON_FONT}
                                    style={{ marginRight: "0rem" }}
                                  />{" "}
                                  请前往查看
                                </a>
                              </div>
                            </div>
                          ) : (
                            <div style={{ paddingLeft: 20 }}>
                              {item.notice_body||item.msg_body}
                              { currentCateValue=='collaborate_apply_receive' &&
                                <div style={{ marginTop: 10 }}>
                                  {item.reply_status==0 &&
                                  <a onClick={()=>{me.collaborate_reply(item)}}>
                                    <Icon
                                      name={"icon-link-o"}
                                      type={IconTypes.ICON_FONT}
                                      style={{ marginRight: "0rem" }}
                                    />{" "}
                                    请前往处理
                                  </a>}
                                  {item.reply_status==1 && <span>已审核--同意{item.reply_text && <span>({item.reply_text})</span>}</span>}
                                  {item.reply_status==2 && <span>已审核--不同意{item.reply_text && <span>({item.reply_text})</span>}</span>}
                                </div>
                              }
                              { currentCateValue=='collaborate_apply' &&
                                <div style={{ marginTop: 10 }}>
                                  {item.reply_status==0 && <span>待审核</span>}
                                  {item.reply_status==1 && <span>已审核--同意{item.reply_text && <span>({item.reply_text})</span>}</span>}
                                  {item.reply_status==2 && <span>已审核--不同意{item.reply_text && <span>({item.reply_text})</span>}</span>}
                                </div>
                              }
                            </div>
                          )
                        }
                      />{" "}
                    </List.Item>
                  )}
                />
              ) : loadingData === true ? (
                <Empty
                  imageStyle={{
                    height: 60,
                  }}
                  description={<span>正在加载...</span>}
                  style={{
                    marginTop: 60,
                  }}
                />
              ) : (
                <Empty
                  imageStyle={{
                    height: 60,
                  }}
                  description={<span>您没有信息</span>}
                  style={{
                    marginTop: 60,
                  }}
                />
              )}
            </div>
            {currentDataTotal > currentPageSize ? (
              <div className={style["notice-pagination"]}>
                <Pagination
                  current={currentPageNo}
                  defaultCurrent={1}
                  showTotal={(total) => (
                    <span className={style["notice-pagination-label"]}>
                      共 {total} 条
                    </span>
                  )}
                  total={currentDataTotal}
                  pageSize={currentPageSize}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30", "40", "50", "100"]}
                  onChange={(page) => {
                    this.changePageNo(page);
                  }}
                  onShowSizeChange={(current, size) => {
                    this.changePageSize(size);
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={style["clear-fix"]} />

        <div className={style["logo"]}>
          <a href="/">
            <img src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'} alt={"炬图"} />
          </a>
        </div>
        <BadgeInfo
          currentUserInfo={this.props.userInfo}
          doChangePwd={this.props.doChangePwd}
          onLogout={this.props.doLogout}
          history={this.props.history}
          changeUserBasicInfoStatus={this.props.changeUserBasicInfoStatus}
          onSaveUserBasicInfo={this.props.onChangeUserBasicInfo}
          style={{
            visibility: SysUIConfig.BadgeInfo.visibility,
            top: "17px",
          }}
        />
        <div className={style["lesson-frame"]}>
          <div className={style["lesson-content"]}>
            <div className={style["lesson-text-container"]}>
              <span>参加微创业</span>
            </div>
            <div className={style["lesson-image-container"]}>
              <img src={"/assets/home/video.png"} alt={"参加微创业"} />
            </div>
          </div>
          <div className={style["lesson-popup"]}>
            <h4>观看微创业介绍视频</h4>
            <ol>
              <li>
                <a
                  href={"http://video.joinmap.ai/guide-E1129.mp4"}
                  target={"_blank"}
                >
                  产品基本操作
                </a>
              </li>
              <li>
                <a
                  href={"http://video.joinmap.ai/kc2-bzkhcg.mp4"}
                  target={"_blank"}
                >
                  容易上手的场景
                </a>
              </li>
              <li>
                <a
                  href={"http://video.joinmap.ai/kc3-cyhms.mp4"}
                  target={"_blank"}
                >
                  谋生和创业
                </a>
              </li>
            </ol>
          </div>
        </div>
        <div className={style["mini-program-frame"]}>
          <div className={style["mini-program-content"]}>
            <div className={style["mini-program-text-container"]}>
              <span>手机小程序</span>
            </div>
            <div
              className={`${style["mini-program-image-container"]} ${style["mini-program-image-small"]}`}
            >
              <img
                src={"/assets/home/mini-program.jpg"}
                className={style["mini-program-image-small"]}
                alt={"小程序码"}
              />
            </div>
          </div>
          <div
            className={`${style["mini-program-popup"]} ${style["mini-program-image-container"]}`}
          >
            <img src={"/assets/home/mini-program.jpg"} alt={"小程序码"} />
          </div>
        </div>
        <Copyright className={style["copyright"]} />
        <Feedback
          feedbackLoading={this.props.feedbackLoading}
          feedbackResult={this.props.feedbackResult}
          submitFeedbackAction={this.props.submitFeedbackAction}
        />
        <StickyNodes />
        <NodeTodo currentUserId={me.props.userInfo.userId} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    userInfo: state.account.userInfo,
    changePwdStatus: state.account.changePwdStatus,
    feedbackLoading: state.system.feedbackLoading,
    feedbackResult: state.system.feedbackResult,
    changeUserBasicInfoStatus: state.account.changeUserBasicInfoStatus,
  }),
  (dispatch) => ({
    doLogout: () => dispatch(doLogoutAction()),
    submitFeedbackAction: (params) => dispatch(submitFeedbackAction(params)),
    doChangePwd: (oldPwd, newPwd, strength) =>
      dispatch(doChangePwdAction(oldPwd, newPwd, strength)),
    resetChangePwdStatus: () => dispatch(changePwdInitAction()),
    onChangeUserBasicInfo: (userInfo) =>
      dispatch(changeUserBasicInfoAction(userInfo)),
  })
)(NoticeView);
