import React from "react";
import PropTypes from 'prop-types';
import PB, {SimplePB} from "@/libs/simplePB";
import badgeInfoStyle from '@/style/components/mainView/main.badgeInfo.less';
import {AutoComplete, Input, message} from 'antd';
import Icon from '@/components/common/common.icon';
import {IconTypes} from '@/constants/common';
import ViewManager from '@/components/common/common.viewManager';
import intl from 'react-intl-universal';

class SearchInputBar extends React.PureComponent {
  
  state = {
    searchWord: '', // 搜索关键词
    viewTagsList: [], // 图谱标签列表
    autoCompleteData: [], // 搜索自动填充数据列表
  };
  
  prevSearchWord = ''; // 上一个搜索关键词
  
  // 根据关键词搜索图谱
  onSearchViews() {
    let me = this;
    let searchWord = me.state.searchWord.trim() || '';
    const {params: {channel = 0}}=this.props;
    
    if (searchWord) {
      me.prevSearchWord = searchWord;
      if(me.props.history.location.pathname.includes('/mainview/dataset/datasetinfo')){
        me.props.history.push({pathname: '/mainview/dashboard/dataset', state: {searchWord: searchWord}});
        return ;
      }
      if(me.props.history.location.pathname.includes('/mainview/dashboard/dataset')){
        PB.emit('search', 'search.dataset.searchWord', {searchWord});
        return ;
      }
      if (channel===0) {
        me.props.history.push({pathname: '/mainview/dashboard/search', state: {searchWord: searchWord}});
        if (me.props.history.location.pathname.includes('/mainview/dashboard/search')) {
          PB.emit('search', 'search.view.searchWord', {searchWord});
        }
      } else {
        PB.emit('search', 'search.view.searchWord', {searchWord});
      }
      
    } else {
      me.prevSearchWord = '';
      message.info('请输入搜索关键词');
      //me.props.history.push(`/mainview/dashboard/all`);
    }
    
    /* me.prevSearchWord = searchWord;
     me.props.history.push({pathname: '/mainview/dashboard/search', state: {searchWord: searchWord}});
     // 在当前页是搜索页时，通过pb来通信
     if (me.props.history.location.pathname.includes('/mainview/dashboard/search')) {
       PB.emit('search', 'search.view.searchWord', {searchWord});
     }*/
  }
  
  /**
   * 关键词搜节点
   */
  onSearchNodes = () => {
    if (this.state.searchWord) {
      window.open(`/search/nodes?wd=${this.state.searchWord}`, '_blank');
    } else {
      message.warning(intl.get('Custom.form.enterKeywordSearch'));
    }
  }
  
  getViewTags() {
    let me = this;
    
    ViewManager.getViewTags().then(res => {
      if (res.code === 0) {
        if (res.data && res.data.length > 0) {
          let viewTags = res.data.map(item => item.tagName).reverse();
          // 去重
          let autoCompleteData = viewTags.filter((item, index, arr) => item && arr.indexOf(item, 0) === index);
          me.setState({
            viewTagsList: res.data,
            autoCompleteData: autoCompleteData,
          })
        }
      }
    }).catch(err => {
      me.setState({
        viewTagsList: [],
        autoCompleteData: [],
      })
    });
  }
  
  componentDidMount() {
    const me = this;
    if (me.props.history.location.pathname.includes('/mainview/dashboard/search')) {
      const locationStateSearchWord = me.props.history.location && me.props.history.location.state && me.props.history.location.state.searchWord || '';
      if (locationStateSearchWord) {
        me.setState({
          searchWord: locationStateSearchWord,
        });
      } else {
        me.props.history.push(`/mainview/dashboard/all`);
      }
      /* me.setState({
         searchWord: locationStateSearchWord,
       });*/
    }
    
    // 获取看板标签，搜索时作候选词使用
    me.getViewTags();
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pathname.includes('/mainview/dashboard/search') && !this.props.pathname.includes('/mainview/dashboard/search')) {
      this.setState({
        searchWord: '',
      });
    }
  }
  
  componentWillUnmount() {
    PB.remove(this);
    // 组件卸载时清空数据
    this.setState({
      searchWord: '', // 搜索关键词
      viewTagsList: [], // 图谱标签列表
      autoCompleteData: [], // 搜索自动填充数据列表
    })
  }
  
  render() {
    let me = this;
    
    return (
      <React.Fragment>
        <div
          className={`${badgeInfoStyle['badge-more-view']} ${badgeInfoStyle['search-input-bar']}`}
          style={{
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            marginRight: 0
          }}
        >
          <AutoComplete
            getPopupContainer={() => document.getElementById('mainBadgeInfo')}
            className={badgeInfoStyle['auto-complete-input']}
            dropdownClassName={`dark-theme ${badgeInfoStyle['auto-complete-dropdown']}`}
            placeholder={intl.get('Custom.form.enterKeywordSearch')}
            allowClear={true}
            defaultActiveFirstOption={false}
            value={me.state.searchWord}
            onChange={value => {
              me.setState({
                searchWord: value,
              })
            }}
            onSelect={value => {
              me.setState({
                searchWord: value,
              }, () => {
                me.onSearchViews();
              });
            }}
            onDropdownVisibleChange={value => {
              if (value) {
                // me.getViewTags();
              }
            }}
          >
            <Input
              onPressEnter={() => {
                me.onSearchViews();
              }}
            />
          </AutoComplete>
        </div>
        
        <div
          className={`${badgeInfoStyle['badge-more-view']} ${badgeInfoStyle['with-text']}`}
          style={{
            borderLeft: 0,
            borderRadius: 0,
            marginLeft: 0
          }}
          onClick={e => {
            e.stopPropagation();
            me.onSearchViews();
          }}
        >
          <Icon
            name={'icon-search'}
            type={IconTypes.ICON_FONT}
            className={`${badgeInfoStyle['icon']}`}
            style={{marginRight: '.5rem'}}
          /> {me.props.history.location.pathname.includes('/dataset')?'搜数据集':intl.get('Custom.view.searchMaps')}
        </div>
        
      </React.Fragment>
    );
  }
}

SearchInputBar.defaultProps = {
  style: {},
  params: {},
};

SearchInputBar.propTypes = {
  history: PropTypes.object, // router的参数
  style: PropTypes.object,
  className: PropTypes.string,
  userInfo: PropTypes.object,
  pathname: PropTypes.string,
  params:PropTypes.object // 一些非必须参数
};

export default SearchInputBar;