import React from 'react';
import QRCode from 'qrcode.react';
import moment from "moment";

import ViewManager from '@/libs/view/ViewManager';
import {withPromiseMessageDecorator} from '@/libs/core-decorators';
import {viewManagerOperationMessages} from "@/constants/messages";
import messageProxy from '@/components/common/common.messageProxy';

import PB from '@/libs/simplePB'

import { Modal, Input, InputNumber, message, Form, Radio, Checkbox, Cascader, Menu, Popover } from 'antd';
import {IconTypes} from "@/constants/common";
import Icon from '@/components/common/common.icon'
import {convertPrice, copyUrlToBoard} from '@/components/common/common.functions'
import {API_userMapCreateCheck } from "@/api/user";
const overrideMessages = {};

// 显示的时间格式
const TimeFormat = 'YYYY-MM-DD HH:mm';

/**
 * @type {typeof ViewManager}
 */
const ViewManagerWrapper = withPromiseMessageDecorator(
    messageProxy(viewManagerOperationMessages, overrideMessages)
)(ViewManager);

export const overrideNextMessage = (method, msg) => {
    overrideMessages[method] = msg;
};

const manager = {
    dataProvider: ViewManagerWrapper,
    createViewInput: undefined,

    // 加载公开图谱（包含收费、免费）
    loadPublishedViewList: ({q, start, limit, channel} = {q: undefined, start: 0, limit: -1, channel: 0}) => {
        return ViewManagerWrapper.loadPublishedViewList({q, start, limit, channel});
    },
    
    // 加载上架图谱（免费）
    loadPublishedViewOfFreeList: ({q, start, limit, channel} = {q: undefined, start: 0, limit: -1, channel: 0}) => {
        return ViewManagerWrapper.loadPublishedViewList('free',  {q, start, limit, channel});
    },
    
    // 加载上架图谱（免费）
    loadPublishedViewOfNoFreeList: () => {
        return ViewManagerWrapper.loadPublishedViewList({q: undefined, start: 0, limit: -1, channel});
    },

    // 加载公开图谱（包含收费、免费）
    loadPublicViewList: ({q, start, limit, teamwork_type, channel} = {q: undefined, start: 0, limit: -1, channel: 0}) => {
        return ViewManagerWrapper.loadPublicViewList({q, start, limit, teamwork_type, channel});
    },

    // 加载公开图谱（包含收费、免费）
    loadPublicViewListV2: ({q, start, limit, teamwork_type, channel} = {q: undefined, start: 0, limit: -1, channel: 0}) => {
        return ViewManagerWrapper.loadPublicViewListV2({q, start, limit, teamwork_type, channel});
    },

    // 加载公开图谱（包含收费、免费）
    loadPublicViewListAll: ({q, start, limit, teamwork_type, channel,ignore_owned_by_ai} = {q: undefined, start: 0, limit: -1, channel: 0,ignore_owned_by_ai}) => {
        return ViewManagerWrapper.loadPublicViewListAll({q, start, limit, teamwork_type, channel,ignore_owned_by_ai});
    },
    
    // 加载个人图谱（包含创建、购买）
    loadUserViewList: (...args) => {
        return ViewManagerWrapper.loadUserViewList(...args);
    },

    // 加载我是组长图谱（包含创建、购买）
    loadUserIsMasterViewList: (currentUserId, {q, e, start, limit, teamwork_type, channel} = {q: undefined, e: undefined, start: 0, limit: -1, channel: 0}) => {
        return ViewManagerWrapper.loadUserIsMasterViewList(currentUserId, {q, e, start, limit, channel});
    },

    // 加载我是成员图谱（不包含创建的）
    loadUserIsMemberViewList: (currentUserId, {q, start, limit, teamwork_type, channel} = {q: undefined, start: 0, limit: -1, channel: 0}) => {
        return ViewManagerWrapper.loadUserIsMemberViewList(currentUserId, {q, start, limit, channel});
    },

    // 加载个人图谱（包含创建、购买）
    loadAccessibleViewList: ({q, start, limit, teamwork_type, channel} = {q: undefined, start: 0, limit: -1, channel: 0}) => {
        return ViewManagerWrapper.loadAccessibleViewList({q, start, limit, teamwork_type, channel});
    },
    
    // 加载（个人）协作图谱
    loadTeamworkViewList: (...args) => {
        return ViewManagerWrapper.loadTeamworkViewList(...args);
    },

    // 加载推荐图谱
    loadRecommendViewList: () => {
        return ViewManagerWrapper.loadRecommendViewList();
    },

    // 取首页图谱列表（未登录下所有可访问图谱）
    loadGuestPublicViewList: ({q, start, limit, teamwork_type, channel} = {q: undefined, start: 0, limit: -1,  teamwork_type: 0, channel: 0}) => {
        return ViewManagerWrapper.loadGuestPublicViewList({q, start, limit, teamwork_type, channel});
    },

    // 获取首页免费图谱列表（未登录下所有免费图谱）
    loadGuestPublicViewListOfFree: ({q, start, limit, channel} = {q: undefined, start: 0, limit: -1, channel: 0}) => {
        return ViewManagerWrapper.loadGuestPublicViewListOfFree({q, start, limit, channel});
    },

    // 通过名称搜索图谱列表
    loadSearchViewList: ({q, limit, teamwork_type, channel, tag} = {q: undefined, limit: -1, channel: 0, tag: undefined}) => {
        return ViewManagerWrapper.loadSearchViewList({q, limit, teamwork_type, channel, tag});
    },

    // 通过图谱内节点搜索图谱列表
    loadSearchViewListByNode: ({q, limit, channel} = {q: undefined, limit: -1, channel: 0}) => {
        return ViewManagerWrapper.loadSearchViewListByNode({q, limit, channel});
    },

    // 加载协作成员列表
    loadViewTeamworkMembers: (viewId, limit = -1, orderBy = undefined, orderType = undefined, start = 0) => {
        return ViewManagerWrapper.loadViewTeamworkMembers(viewId, limit, orderBy, orderType, start);
    },

    // 添加图谱协作成员
    addViewTeamworkMember: (viewId, userId, token) => {
        return ViewManagerWrapper.addViewTeamworkMember(viewId, userId, token);
    },

    // 移除图谱协作成员
    removeViewTeamworkMember: (viewId, userId) => {
        return ViewManagerWrapper.removeViewTeamworkMember(viewId, userId);
    },

    // 获取图谱协作配置
    loadViewTeamworkSettings: (viewId, key = undefined) => {
        return ViewManagerWrapper.loadViewTeamworkSettings(viewId, key);
    },

    setViewTeamworkSettings: (viewId, type, autoSetSharingType = 1) => {
        return ViewManagerWrapper.setViewTeamworkSettings(viewId, type, autoSetSharingType);
    },

    setViewTeamworkSettingsPartially: (viewId, type, autoSetSharingType = 1, meta) => {
        return ViewManagerWrapper.setViewTeamworkSettingsPartially(viewId, type, autoSetSharingType, meta);
    },

    // 加载门票列表
    loadViewTeamworkTickets: (viewId, parameters) => {
        return ViewManagerWrapper.loadViewTeamworkTickets(viewId, parameters);
    },

    // 添加门票
    addViewTeamworkTicket: (viewId, config) => {
        return ViewManagerWrapper.addViewTeamworkTicket(viewId, config);
    },

    // 删除门票
    removeViewTeamworkTicket: (viewId, code) => {
        return ViewManagerWrapper.removeViewTeamworkTicket(viewId, code);
    },

    // 将一个用户的所有图谱相关信息转移到另一个用户中
    moveViewInfoByAccessToken: (accessToken) => {
        return ViewManagerWrapper.moveViewInfoByAccessToken(accessToken);
    },

    // 将一个用户的所有交易相关信息转移到另一个用户中
    moveTransInfoByAccessToken: (accessToken) => {
        return ViewManagerWrapper.moveTransInfoByAccessToken(accessToken);
    },

    // 获取图谱封面信息
    loadViewCover: viewId => {
        return ViewManagerWrapper.loadViewCover(viewId);
    },

    // 设置图谱封面信息
    setViewCover: ({viewId, coverData}) => {
        return ViewManagerWrapper.setViewCover(viewId, coverData);
    },


    // 获取公开图谱封面信息
    loadViewPublicCover: viewId => {
        return ViewManagerWrapper.loadViewPublicCover(viewId);
    },

    /**
     * 复制图谱的浏览网址 todo:会报错
     * @param view
     */
    copyUrlToBoard: (view) => {
        copyUrlToBoard(window.location.protocol + '//' +window.location.host + `/mainview/relation/${view.viewId}`,
          () => message.info("已将该图谱链接复制到剪贴板"));
    },

    /**
     * 新建图谱
     *
     * @param newInfo
     * @param currentUserId 创建人id
     */
    addView: (newInfo, currentUserId) => {
        return ViewManagerWrapper.createView(newInfo, currentUserId);
    },

    /**
     * 获取指定图谱信息
     *
     * @param viewId
     */
    getViewById: (viewId) => {
        return ViewManagerWrapper.getViewById(viewId);
    },

    /**
     * 修改图谱标题和描述信息
     *
     * @param view
     */
    editView: (view) => {
        return ViewManagerWrapper.updateView(view.viewId, { name: view.name, desc: view.desc, tags: view.tags});
    },

    // 节点及关联点
    loadTopNodesList: (viewId, {start, limit}) => {
        return ViewManagerWrapper.loadTopNodesList(viewId, {start, limit});
    },

    loadSubGraphNodesList: (viewId, params) => {
        return ViewManagerWrapper.loadSubGraphNodesList(viewId, params);
    },
    /**
     * 删除图谱
     */
    delView: (view, callback) => {
        const delViewModal = Modal.confirm({
            centered: true,
            destroyOnClose: true,
            title: "删除图谱",
            icon: <Icon name="delete"/>,
            okText: "确认删除",
            cancelText: "取消",
            width: 600,
            content:
                <div style={{
                    height: '100%',
                    width: '100%',
                    minHeight: '180px',
                    // paddingTop: '10px',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <div style={{
                        flex: '1',
                        height: '100%',
                    }}>
                        <div>标题：<b>{view.name}</b></div>
                        {view.nick ? <div>作者：<b>{view.nick}</b></div> : null}
                        {/*<div>价格：<b>{convertPrice(view.price)}元</b></div>*/}
                        {view.createTime ? <div>创建：<b>{moment(view.createTime).format(TimeFormat)}</b></div> : null}
                        {view.updateTime ? <div>更新：<b>{moment(view.updateTime).format(TimeFormat)}</b></div> : null}
                        <br/>
                        <div style={{color: 'red'}}><Icon name='exclamation-circle'/> 删除操作不可恢复，请谨慎操作。</div>
                    </div>
                </div>,
            onOk: () => {
                ViewManagerWrapper.deleteView(view.viewId).then((res) => {
                    message.success('图谱已删除');
                    callback && callback({action: 'remove', viewId: view.viewId, success: true})
                }).catch(e => {
                    // console.log('common.viewManager->editView->删除图谱信息出错 e=', e)
                    // console.log('common.viewManager->editView->删除图谱信息出错 view=', view)
                    // message.error('网络不稳定，请在窗口关闭后重做上架操作。...', 3).then(() => payViewModal.destroy())
                    message.error('网络不稳定，请尝试重新删除。', 3);
                    callback && callback({action: 'remove', viewId: view.viewId, success: false})
                })
            },
            onCancel: () => {
                delViewModal.destroy();
                message.warning('您取消了删除操作')
            },
        })
    },
    
    // 复制图谱
    payFreeView: (view, callback, propsHistory) => {
        API_userMapCreateCheck().then((response) => {
            if(response && response.data){
                if(response.data.code === 0){
                    let user_info = response.data.data;
                    if(user_info && user_info.mapCreate){
                        const payFreeViewModal = Modal.confirm({
                            centered: true,
                            destroyOnClose: true,
                            title: "复制图谱",
                            icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                            okText: "确认",
                            cancelText: "取消",
                            width: 520,
                            content: <span>复制图谱后，当前图谱将复制到您的【我是组长】列表中，<br/>您将成为复制图谱的管理员。是否确定复制当前图谱？</span>,
                            onOk: () => {
                                ViewManagerWrapper.orderFreeView(view.viewId).then(res => {
                                    // console.log('common.viewManager->saleView->购买免费图谱 res=', res)
                                    callback && callback({action: 'pay', viewId: view.viewId, success: true});
                                    
                                    const paySuccessFreeViewModal = Modal.confirm({
                                        centered: true,
                                        destroyOnClose: true,
                                        title: "复制完成",
                                        icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                                        okText: "打开",
                                        cancelText: "取消",
                                        width: 400,
                                        content: <span>已经成功复制了所选图谱。<span>是否立即打开图谱？</span></span>,
                                        onOk: () => {
                                            // 打开刚刚购买的图谱
                                            // that.openView(view)
                                            window.open('/mainview/relation/' + res.newViewId, '_blank')
                                        },
                                        onCancel: () => {
                                            paySuccessFreeViewModal.destroy();
                                        },
                                    })
                                }).catch(e => {
                                    // console.log('common.viewManager->saleView->购买免费图谱出错 e=', e)
                                    // console.log('common.viewManager->saleView->购买免费图谱出错 view=', view)
                                    message.error('网络不稳定，请尝试重新操作。');
                                    callback && callback({action: 'pay', viewId: view.viewId, success: false})
                                })
                            },
                            onCancel: () => {
                                message.warning('您取消了复制图谱操作');
                                payFreeViewModal.destroy();
                            },
                        })
                    }else{ 
                        //message.error('已超过允许创建的图谱数，请升级会员', 3).then(() => {
                        //    PB.emit('account', 'user_upgrade.show_modal', {'up_by':'creat_map'});
                        //  });
    
                  
                      Modal.error({
                        title: '超过限制',
                        content: (
                          <span>已超过允许创建的图谱数，请升级会员</span>
                        ),
                        okText: '升级会员',
                        cancelText: '取消',
                        onOk: () => {
                          PB.emit('account', 'user_upgrade.show_modal', {'up_by':'creat_map'});
                        },
                        mask: true,
                        maskClosable: false,
                        okCancel: true,
                      });
                    }
                }else{
                    message.info(response.data.msg, 3);
                }
            }else{
                message.info('网络不稳定，请稍后再试。', 3);
            }
        }).catch((e) => {
            message.error('网络不稳定，请稍后再试。', 3);
            console.error(e);
        });
    },

    /**
     * 购买图谱
     * @param view 所购买的图谱
     * @param callback 购买后的回调 true=购买成功，false=购买失败
     */
    payView: (view, callback) => {
        // let that = this
        ViewManagerWrapper.orderView(view.viewId).then(({url, orderId}) => {
            const payViewModal = Modal.confirm({
                centered: true,
                title: "购买图谱",
                icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                okText: "我已支付成功",
                cancelText: "取消",
                width: 600,
                content:
                    <div style={{
                        height: '100%',
                        width: '100%',
                        minHeight: '300px',
                        paddingTop: '0px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        <div style={{
                            flex: '1',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <div style={{flex: '1'}}>
                                <div>订单：<b>{orderId}</b></div>
                                <div>标题：<b>{view.name}</b></div>
                                <div>作者：<b>{view.nick}</b></div>
                                <div>价格：<b>{convertPrice(view.price)}元</b></div>
                                {/*<div>节点：<b>{view.nodeCount}</b>个</div>*/}
                                {view.createTime ?
                                    <div>创建：<b>{moment(view.createTime).format(TimeFormat)}</b></div> : null}
                                {view.updateTime ?
                                    <div>更新：<b>{moment(view.updateTime).format(TimeFormat)}</b></div> : null}
                            </div>
                            <div style={{flex: '0 1 35%', textAlign: 'center'}}>
                                <span><Icon name="wechat" style={{fontSize: '1rem', color: 'green'}}/> 微信扫码支付</span>
                                <div style={{
                                    padding: '10px 10px 5px 10px ',
                                    margin: '0 auto',
                                    maxWidth: '150px',
                                    textAlign: 'center',
                                }}>
                                    <QRCode value={url} title={'请使用微信扫码支付'}></QRCode>
                                </div>
                                <span>交易金额：<b style={{color: 'red'}}>{convertPrice(view.price)} 元</b><br/> </span>
                            </div>
                        </div>
                        <div style={{flex: '0 1 30%'}}>
                            <div>
                                <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>成功</b>，请点击 <b
                                style={{color: 'red'}}>【我已支付成功】</b> 按钮。
                            </div>
                            <div>
                                <Icon name='exclamation-circle'/> 若手机支付<b style={{color: 'red'}}>失败</b>，请点击 <b
                                style={{color: 'red'}}>【取消】</b>重新购买。
                            </div>
                            <div>
                                <Icon name='exclamation-circle'/> 购买成功的图谱请在 【我的图谱】 里查看。
                            </div>
                        </div>
                    </div>,
                onOk: () => {
                    ViewManagerWrapper.queryOrder(orderId).then(res => {
                        if (res.state === 1) { // 支付成功
                            // 回调
                            callback && callback({action: 'pay', viewId: view.viewId, success: true})
                            // 提示
                            const saleSuccessViewModal = Modal.confirm({
                                centered: true,
                                destroyOnClose: true,
                                title: <div>{view.price > 0 ? "购买完成" : "复制完成"}</div>,
                                icon: <Icon type={IconTypes.ICON_FONT} name={view.price > 0 ? "icon-shopping-cart-1" : "icon-fuzhi"}/>,
                                okText: "打开",
                                cancelText: "取消",
                                width: 400,
                                content: <div>{view.price > 0 ? (
                                  <span>已经成功购买了图谱。<br/>
                                  支付金额：<span style={{color: 'red'}}>{convertPrice(res.price)}元</span>。<br/>
                                  <span>是否立即打开图谱？</span></span>
                                ) : (
                                  <span>已经成功复制了图谱。<br/>
                                  <span>是否立即打开图谱？</span></span>
                                )}</div>,
                                onOk: () => {
                                    // 打开刚刚购买的图谱,要用新图谱的id
                                    window.open('/mainview/relation/' + res.newViewId, '_blank')
                                },
                                onCancel: () => {
                                    saleSuccessViewModal.destroy();
                                },
                            })
                        } else if (res.state === 0) { // 待支付
                            // 提示
                            const saleWaitingViewModal = Modal.warning({
                                centered: true,
                                destroyOnClose: true,
                                title: "支付未完成",
                                icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                                okText: "知道了",
                                cancelText: "取消",
                                width: 400,
                                content: <div>
                                    <div>请在微信检查支付情况。</div>
                                    <div>关闭窗口后，重新购买。</div>
                                </div>,
                                onOk: () => {
                                    saleWaitingViewModal.destroy();
                                },
                            })
                        } else if (res.state === 2) {//已失效
                            // 提示
                            const saleOutTimeViewModal = Modal.warning({
                                centered: true,
                                destroyOnClose: true,
                                title: "支付未完成",
                                icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                                okText: "知道了",
                                cancelText: "取消",
                                width: 400,
                                content: <span><span>订单已经过期，</span> 请关闭窗口后，重新购买</span>,
                                onOk: () => {
                                    saleOutTimeViewModal.destroy();
                                },
                            })
                        } else if (res.state === 3) { // 已退款
                            // 提示
                            const saleRefundViewModal = Modal.warning({
                                centered: true,
                                destroyOnClose: true,
                                title: "支付未完成",
                                icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                                okText: "知道了",
                                cancelText: "取消",
                                width: 400,
                                content: <span><span>交易款已退回，</span> 请关闭窗口后，重新购买</span>,
                                onOk: () => {
                                    saleRefundViewModal.destroy();
                                },
                            })
                        } else {
                            // 提示
                            const saleRefundViewModal = Modal.warning({
                                centered: true,
                                destroyOnClose: true,
                                title: "交易未成功",
                                icon: <Icon type={IconTypes.ICON_FONT} name="icon-shopping-cart-1"/>,
                                okText: "知道了",
                                cancelText: "取消",
                                width: 400,
                                content: <span><span>交易未成功，</span> 请关闭窗口后，重新购买</span>,
                                onOk: () => {
                                    saleRefundViewModal.destroy();
                                },
                            })
                        }
                    }).catch()
                    
                    
                    // todo：支付成功后，跳转我的图谱并刷新？？
                },
                onCancel: () => {
                    message.warning('您取消了支付操作')
                    payViewModal.destroy();
                },
            })
        }).catch(e => {
            // console.log('common.viewManager->payView->获取订单出错 e=', e)
            // console.log('common.viewManager->payView->获取订单出错 view=', view)
            // message.error('网络不稳定，请在窗口关闭后重做上架操作。...', 3).then(() => payViewModal.destroy())
            message.error('网络不稳定，请稍后重新发起购买请求。', 3)
            callback && callback({action: 'pay', viewId: view.viewId, success: false})
        })
        
    },
    
    /**
     * 开放复制（相当上架价格为0）
     * @param view
     * @param callback 上架后的回调 true=上架成功，false=上架失败
     */
    setCopyView: (view, callback) => {
        // 判断是否已经设置了开放复制了
        if (!!(view.up && view.up == 1)) {
            message.warning('图谱已经设置了开放复制功能');
            return;
        }

        let price = 0; // 图谱价格
        ViewManagerWrapper.publishToStore(view.viewId, price).then(res => {
            // console.log('common.viewManager->saleView->上架成功 res=', res)
            message.success('设置成功');
            callback && callback({action: 'sale', viewId: view.viewId, success: true, viewInfo: res});
        }).catch(e => {
            // console.log('common.viewManager->saleView->上架请求出错 e=', e)
            // console.log('common.viewManager->saleView->上架请求出错 view=', view)
            message.error('网络不稳定，请尝试重做上架操作。');
            callback && callback({action: 'sale', viewId: view.viewId, success: false})
        })
    },

    /**
     * 取消开放复制（要先下架）
     * @param view
     * @param callback 下架后的回调 true=下架成功，false=下架失败
     */
    setNotCopyView: (view, callback) => {
        // 图谱还没有上架，无法进行下架操作
        if (!(view && view.up == 1)) {
            callback && callback(null);
            return;
        }
        ViewManagerWrapper.removeFromStore(view.viewId).then(res => {
            // console.log('common.viewManager->saleView->下架成功 res=', res)
            callback && callback({action: 'cancel_sale', viewId: view.viewId, success: true, viewInfo: res});
        }).catch(e => {
            // console.log('common.viewManager->saleView->下架请求出错 e=', e)
            // console.log('common.viewManager->saleView->下架请求出错 view=', view)
            message.error('网络不稳定，请尝试重做下架操作。');
            callback && callback({action: 'cancel_sale', viewId: view.viewId, success: false})
        })
    },

    /**
     * 上架图谱
     * @param view
     * @param callback 上架后的回调 true=上架成功，false=上架失败
     */
    saleView: (view, callback) => {
        // 判断是否已经上架了
        if (!!(view.up && view.up == 1)) {
            // if(true){ // 测试
            // message.warning('图谱已经在平台上架销售了。如需')
            const saleAgainViewModal = Modal.warning({
                centered: true,
                destroyOnClose: true,
                title: "重复上架提醒",
                icon: <Icon type={IconTypes.ICON_FONT} name="icon-shelf-on"/>,
                content: <span>图谱已经在平台上架销售了，请勿重复上架操作。<br/>如需修改图谱交易价格，请先下架，修改价格后重新上架销售。</span>,
                onOk: () => {
                    saleAgainViewModal.destroy();
                },
            })
            return null
        }
        let price = 0 // 图谱价格
        const saleViewModal = Modal.confirm({
            centered: true,
            destroyOnClose: true,
            title: "上架销售",
            icon: <Icon type={IconTypes.ICON_FONT} name="icon-shelf-on"/>,
            okText: "确认上架",
            cancelText: "取消",
            width: 600,
            content:
                <div style={{
                    height: '100%',
                    width: '100%',
                    minHeight: '200px',
                    paddingTop: '0px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                    <div style={{
                        flex: '1',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <div style={{flex: '1'}}>
                            <div>标题：<b>{view.name}</b></div>
                            {/*<div>作者：<b>{view.nick}</b></div>*/}
                            <div>节点：<b>{view.nodeCount}</b>个</div>
                            {view.createTime ? <div>创建：<b>{moment(view.createTime).format(TimeFormat)}</b></div> : null}
                            {view.updateTime ? <div>更新：<b>{moment(view.updateTime).format(TimeFormat)}</b></div> : null}
                        </div>
                        <div style={{flex: '0 1 35%'}}>
                            <div style={{
                                margin: '0 auto',
                                // textAlign: 'left'
                            }}>
                                <span>设置售价：</span>
                                <InputNumber
                                    min={0}
                                    size="large"
                                    autoFocus={true}
                                    placeholder="请输入价格"
                                    defaultValue={price}
                                    // formatter={value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    // parser={value => value.replace(/\￥\s?|(,*)/g, '')}
                                    style={{marginBottom: "1rem", width: '100px'}}
                                    onChange={(v) => {
                                        // console.log('设置的价格：v=', v)
                                        if (v === 0) {
                                            price = 0
                                            document.getElementById('priceForSaleModal').innerText = '免费图谱'
                                        } else if (v < 0) {
                                            price = null
                                            document.getElementById('priceForSaleModal').innerText = '价格不能小于零'
                                        } else if (v > 0) {
                                            price = v
                                            // console.log('common.viewManager->saleView->计算价格 price=', price)
                                            document.getElementById('priceForSaleModal').innerText = ''
                                        }
                                    }}
                                /> 元
                                <div id={'priceForSaleModal'} style={{color: 'red', height: '21px'}}>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div style={{flex: '0 1 30%'}}>
                        <div>
                            <Icon name='exclamation-circle'/> 价格设置为 0 的图谱将成为<b style={{color: 'red'}}>免费</b>图谱
                        </div>
                    </div>
                </div>,
            onOk: () => {
                // console.log('common.viewManager->saleView->上架请求前的 price=', price)
                price = convertPrice(price, '分')
                ViewManagerWrapper.publishToStore(view.viewId, price).then(res => {
                    // console.log('common.viewManager->saleView->上架成功 res=', res)
                    const saleSuccessViewModal = Modal.success({
                        centered: true,
                        destroyOnClose: true,
                        title: "上架成功",
                        icon: <Icon type={IconTypes.ICON_FONT} name="icon-shelf-on"/>,
                        content: <span>图谱已经成功上架。<br/>如需修改图谱交易价格，请先下架，修改价格后重新上架销售。</span>,
                        onOk: () => {
                            callback && callback({action: 'sale', viewId: view.viewId, success: true, viewInfo: res})

                            saleSuccessViewModal.destroy();
                        },
                    })
                }).catch(e => {
                    // console.log('common.viewManager->saleView->上架请求出错 e=', e)
                    // console.log('common.viewManager->saleView->上架请求出错 view=', view)
                    message.error('网络不稳定，请尝试重做上架操作。')
                    callback && callback({action: 'sale', viewId: view.viewId, success: false})
                })
            },
            onCancel: () => {
                saleViewModal.destroy();
                message.warning('已取消上架操作')
            },
        })
    },
    
    /**
     * 下架图谱
     * @param view
     * @param callback 下架后的回调 true=下架成功，false=下架失败
     */
    cancelSaleView: (view, callback) => {
        // 判断是否已经上架了
        if (!(view.up && view.up == 1)) {
            // if(true){ // 测试
            // message.warning('图谱已经在平台上架销售了。如需')
            const cancelAgainViewModal = Modal.warning({
                centered: true,
                destroyOnClose: true,
                title: "下架提醒",
                icon: <Icon type={IconTypes.ICON_FONT} name="icon-shelf-off"/>,
                content: <span>图谱还没有上架，无法进行下架操作。</span>,
                onOk: () => {
                    cancelAgainViewModal.destroy();
                },
            })
            return null
        }
        
        const cancelSaleViewModal = Modal.confirm({
            centered: true,
            destroyOnClose: true,
            title: "下架图谱",
            icon: <Icon type={IconTypes.ICON_FONT} name="icon-shelf-off"/>,
            okText: "确认下架",
            cancelText: "取消",
            width: 600,
            content:
                <div style={{
                    height: '100%',
                    width: '100%',
                    minHeight: '180px',
                    paddingTop: '0px',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <div style={{
                        flex: '1',
                        height: '100%',
                    }}>
                        <div>标题：<b>{view.name}</b></div>
                        {/*<div>作者：<b>{view.nick}</b></div>*/}
                        <div>价格：<b>{view.price == 0 ? '免费' : convertPrice(view.price) + '元'}</b></div>
                        <div>节点：<b>{view.nodeCount}</b>个</div>
                        {view.createTime ? <div>创建：<b>{moment(view.createTime).format(TimeFormat)}</b></div> : null}
                        {view.updateTime ? <div>更新：<b>{moment(view.updateTime).format(TimeFormat)}</b></div> : null}
                        <br/>
                        <div><Icon name='exclamation-circle'/> 下架后，图谱只出现在 我的图谱 列表里。</div>
                        <div><Icon name='exclamation-circle'/> 下架后，重新设置价格后可再次上架。</div>
                    </div>
                </div>,
            onOk: () => {
                ViewManagerWrapper.removeFromStore(view.viewId).then(res => {
                    // console.log('common.viewManager->saleView->下架成功 res=', res)
                    const cancelSuccessViewModal = Modal.success({
                        centered: true,
                        destroyOnClose: true,
                        title: "下架成功",
                        icon: <Icon type={IconTypes.ICON_FONT} name="icon-shelf-off"/>,
                        content: <span>图谱已经成功下架。<br/>重新设置价格后可以再次上架销售。</span>,
                        onOk: () => {
                            callback && callback({action: 'cancel_sale', viewId: view.viewId, success: true, viewInfo: res})
                            
                            cancelSuccessViewModal.destroy();
                        },
                    })
                }).catch(e => {
                    // console.log('common.viewManager->saleView->下架请求出错 e=', e)
                    // console.log('common.viewManager->saleView->下架请求出错 view=', view)
                    message.error('网络不稳定，请尝试重做下架操作。')
                    callback && callback({action: 'cancel_sale', viewId: view.viewId, success: false})
                })
            },
            onCancel: () => {
                cancelSaleViewModal.destroy();
            },
        })
    },

    /**
     * 将图谱转到指定用户下
     * @param viewId
     * @param userId
     * @returns {*}
     */
    viewTransferToUser: (viewId, userId) => {
        return ViewManagerWrapper.viewTransferToUser(viewId, userId);
    },

    /**
     * 获取图谱标签列表
     * @param q
     * @param start
     * @param limit
     * @returns {*}
     */
    getViewTags: ({q, start, limit} = {q: undefined, start: 0, limit: -1}) => {
        return ViewManagerWrapper.getViewTags({q, start, limit});
    },

    /**
     * 获取跨图谱用户设置
     *
     * @param {string} key 设置Key
     * @param {boolean} forDisplayOnly 是否仅用来展示
     * @return {Promise}
     */
    getCrossViewConfig: (key, forDisplayOnly = true) => {
        return ViewManagerWrapper.getCrossViewConfig(key, forDisplayOnly);
    },

    /**
     * 更新跨图谱用户设置
     *
     * @param {string} key 设置Key
     * @param {boolean} forDisplayOnly 是否仅用来展示
     * @return {Promise}
     */
    updateCrossViewConfig: (key, forDisplayOnly = true) => {
        return ViewManagerWrapper.updateCrossViewConfig(key, forDisplayOnly);
    },

    /**
     * 获取关系图子图截图 -> 查询是否有快照
     * @param viewId
     * @param params
     * @returns {*}
     */
    hasSubGraphSnapshot: (viewId, params = {}) => {
        return ViewManagerWrapper.hasSubGraphSnapshot(viewId, params);
    },
};

export default manager;