import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Dropdown, Tooltip } from 'antd';
import moment from 'moment';
import UserAvatar from 'react-user-avatar';

import { getToken, REQUEST_BASE } from '@/utils/HttpUtil';
import Icon from '@/components/common/common.icon';
import { AvatarColors, IconTypes } from '@/constants/common';
import ViewOperateMenu from '@/components/dashboard/dashboard.view.operate';
import View from '@/components/common/common.viewManager';

import style from '@/style/components/dashboard/dashboard.view.less';
import TimeDisplayField from '@/components/common/common.timeDisplayField';

//import {API_GetViewById} from '@/libs/view/network/api.js';

export default class DatasetInfoCard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			coverData: undefined,
			currentDsetInfo: props.dset,
		};
	}
	openView = dset => {
		//localStorage.setItem(dset.title,JSON.stringify(dset));
		window.open(`/mainview/dataset/datasetinfo/${dset.title}`, '_blank');
		return;
	};
	
	
	componentDidMount() {
		let me = this;
		// 判断是否要读取看板信息
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.dset !== this.props.dset) {
			this.setState({currentDsetInfo: this.props.dset});
		}
	}

	render() {
		let {currentDsetInfo: dset} = this.state;
		console.error('s3',this.state);
		return (
			<div className={`${style['view-card-wrap']} ${this.props.boxSize===1?style['view-card-wrap-1']:''}`} onClick={() => this.openView(dset)} style={{height:'12.6rem'}}>
				<div className={style['card-cover']}>
					{
						dset.thumb ? (
							<img src={'data:image/png;base64,'+dset.thumb}/>
						) : <img src={'/assets/view-default-cover.jpg'}/>
					}
					<div className={style['card-bg']} />
				</div>
				<div className={style['card-info']}>
					<div className={style['header']}>
						<div className={style['view-name']}>{dset.title}</div>
						{/*<div className={style['view-name']}>{view.nameArr}</div>*/}
						<div className={style['item']}>
							
						</div>
					</div>
					<div className={style['body']}>
						<div className={style['owner-info']}>
							
						</div>
					</div>
					<div className={style['footer']}>
						<div className={style['item']} style={{flex: 1}}>
							<Tooltip title={'更新时间'}>
								{dset.dt_add ? (
														<div className={style['item']}>
															<div className={style['icon-box']} style={{paddingRight: '6px'}}>
																<Icon name="icon-Raidobox-o" type={IconTypes.ICON_FONT} /*className={style['icon-down']}*//>
															</div>
															{dset.dt_add}
														</div>
									) : null}
							</Tooltip>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
DatasetInfoCard.defaultProps = {
	readOnly: true,
	// showCornerTip: false,
	callback: () => {
	},
	hasLogin: true,
	searchWord: '',
	boxSize: 0
};
DatasetInfoCard.propTypes = {
	readOnly: PropTypes.bool, // 是否是创建人
	dset: PropTypes.object,
	callback: PropTypes.func, // 图谱操作的回调
	module: PropTypes.string, // 当前显示哪类图谱
	hasLogin: PropTypes.bool, // 是否已经登录
	searchWord: PropTypes.string,
	boxSize: PropTypes.number
};
