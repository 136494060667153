import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Route, Redirect, Switch, withRouter} from 'react-router-dom';
import { Layout } from 'antd';
import style from '@/style/containers/dashboard.less'
import { SysUIConfig } from '@/constants/sys.config';
import BadgeInfo from '@/components/mainView/main.badgeInfo';
import PB from '@/libs/simplePB';
import intl from 'react-intl-universal';

import DataSetInfo from '@/components/dataSet/datasetinfo';
//import style from '@/style/containers/dashboard.less'
//<Route render={() => (<Redirect to={`${this.props.url}/dataset`} />)} />

const {Header, Content} = Layout;

@connect(
	state => ({
		loginStatus: state.account.loginStatus,
		userInfo: state.account.userInfo,
	}),
)

class DataSet extends React.Component {

  state = {
		searchWord: '', // 搜索关键字
		topMenus: [],
		contentWrapSpacing: 100, // 计算图谱列表容器左右间距
	};
  
  componentWillMount() {
		const {loginStatus} = this.props;
		if (loginStatus !== 0) {
		//	this.props.history.push('/login');
		}
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize); //监听窗口大小改变

		PB.sub(this, 'search', 'search.view.searchWord', ({searchWord}) => {
			this.setState({
				searchWord: searchWord,
			})
		})
	}

	componentWillUnmount() {
		PB.remove(this);
		window.removeEventListener('resize', this.handleResize) // 取消监听窗口大小变化
	}

	handleResize = () => {
		let htmlFontSize = 14, // html的响应变化基准
				windowWidth = window.innerWidth,
				viewInfoCardWidth = 26; // 图谱卡片占的宽度，单位rem，需要跟据html的响应变化基准
		// 参照css定义的html的响应设置
		if (windowWidth <= 1920) {
			htmlFontSize = 14;
		} else if (windowWidth > 1920 && windowWidth <= 3999) {
			htmlFontSize = 18;
		} else if (windowWidth > 4000) {
			htmlFontSize = 24;
		}

		const cardWidth = viewInfoCardWidth * htmlFontSize;
		this.setState({
			contentWrapSpacing: (windowWidth - Math.floor(windowWidth / cardWidth) * cardWidth) / 2,
		})
	};

	// 搜索 所有图谱
	searchView = (keyWord) => {
		this.setState({
			searchWord: keyWord,
		});
	};

  render () {
    let me = this;
    const { history, match } = me.props;

    return (
      <Layout className={style['dashboard-layout-wrap']} style={{overflow:'auto'}}>
				<Header className={style['header']}>
					<div className={style['logo']}>
						<img
							onClick={() => {
								this.props.history.push({pathname: '/index', state: {type: 'intro'}})
							}}
							src={intl.get('locale')==='zh-cn'?'/assets/logo-hans.png':'/assets/logo-hans_en.png'}
							alt={intl.get('Custom.general.title')}
						/>
					</div>
					<BadgeInfo currentUserInfo={this.props.userInfo}
					           doChangePwd={this.props.doChangePwd}
					           onLogout={this.props.onLogout}
					           history={this.props.history}
					           changeUserBasicInfoStatus={this.props.changeUserBasicInfoStatus}
					           onSaveUserBasicInfo={this.props.onSaveUserBasicInfo}
					           style={{
					           	visibility: SysUIConfig.BadgeInfo.visibility,
						           // top: '17px',
					           }}
					/>
				</Header>
				<Content>
            <Switch>
              <Route path={`/mainview/dataset/datasetinfo/:id?`}
                    render={() => <DataSetInfo 
                        {...this.props}
                        history={history} 
                        id={match.params.id ? match.params.id : null}/>}/>
              
            </Switch>
        </Content>
      </Layout>
    );
  }
}

DataSet.defaultProps = {
  url: '',
  onLogout: () => {
    console.log('resetChangePwdStatus property is not set.');
  },
};

DataSet.propTypes = {
  url: PropTypes.string.isRequired,
  userInfo: PropTypes.object,
	loginStatus: PropTypes.number,
	history: PropTypes.object,
	onLogout: PropTypes.func,
	doChangePwd: PropTypes.func,
	changeUserBasicInfoStatus: PropTypes.number,
	onSaveUserBasicInfo: PropTypes.func,
	changePwdStatus: PropTypes.number,
	removingViewList: PropTypes.array,
	resetChangePwdStatus: PropTypes.func,
};

export default withRouter(DataSet);