import {httpUtilWithNoMsg as httpUtil, httpUtilDown} from '@/utils/HttpUtil';

// 公开图谱列表
export const API_GetPublicViewList = ({ q, start, limit, teamwork_type, channel}) => {
  return httpUtil.get(`/map_v2/map/list/public`, {
    q,
    start,
    limit,
    teamwork_type,
    channel,
    ignore_owned_by_ai: 1,
  });
  // return httpUtilWithExtend.get(`/views.php?user_id=133&as=public&limit=60`, {q, start, limit, teamwork_type, ignore_owned_by_ai:1})
};

export const API_GetPublicViewListAll = ({ q, start, limit, teamwork_type, channel,ignore_owned_by_ai}) => {
  return httpUtil.get(`/map_v2/map/list/public`, {
    q,
    start,
    limit,
    teamwork_type,
    channel,
    ignore_owned_by_ai: ignore_owned_by_ai,
  });
  // return httpUtilWithExtend.get(`/views.php?user_id=133&as=public&limit=60`, {q, start, limit, teamwork_type, ignore_owned_by_ai:1})
};

export const API_getPublicMapList = (param) => {
  return httpUtil.get(`/map_v2/map/list/public`, param);
};

export const API_getMapList = (param) => {
  return httpUtil.get(`/map_v2/map/list/public`, param);
};

export const API_AddRelationshipClue= (param) => {
  return httpUtil.post(`/view/node_clue`, param);
}

export const API_UpdateRelationshipClue = (nodeClueId, param) => {
  return httpUtil.put(`/view/node_clue/${nodeClueId}`, param);
}

export const API_RemoveRelationshipClue = (nodeClueId) => {
  return httpUtil.delete(`/view/node_clue/${nodeClueId}`);
}

export const API_aiGenerateReport = (param) => {
  return httpUtil.post(`/map_v2/ai/call/generate/report`, param);
};

export const API_getReportTemplateList = (param) => {
  return httpUtil.get(`/map_v2/report/template/list`, param);
};

export const API_saveReportTemplate = (param) => {
  return httpUtil.post(`/map_v2/report/template/save`, param);
};

export const API_deleteReportTemplate = (param) => {
  return httpUtil.post(`/map_v2/report/template/delete`, param);
};

export const API_aiGetTextKey = (param) => {
  return httpUtil.post(`/map_v2/ai/call/text/getkey`, param);
};

export const API_aiTextAutoDecompose = (param) => {
  return httpUtil.post(`/map_v2/ai/call/text/auto/decompose`, param);
};

export const API_matchAICurrentmapNodeList = (viewId,params) => {
  return httpUtil.get(`/map_v2/ai_node/map/${viewId}/node_by_keyword`,params);
};

export const API_matchAINodeList = (params) => {
  return httpUtil.get(`/map_v2/ai_node/node/matchlist`,params);
};

export const API_mapOriginCount = (viewId) => {
  //return httpUtilWithNoMsg.get(`/map_v2/origin/map/${viewId}/origincount`);
  return httpUtil.get(`/map_v2/origin/map/${viewId}/origincount`);
};

export const API_mapOriginNodeList = (viewId,params) => {
  return httpUtil.get(`/map_v2/origin/map/${viewId}/node_from_origin`,params);
};

export const API_processJsonAll = (viewId,params) => {
  return httpUtil.get(`/map_v2/long_process/map/${viewId}/json_all`,params);
};

export const API_getJsonURL = (filename) => {
  return httpUtil.get(`/map_v2/down/file/json/${filename}`);
};

export const API_getQiNiuTextFile = (filename) => {
  return httpUtil.get(`/map_v2/down/qi_file/text/${filename}`);
};

export const API_logUserUsageMap = (viewId,params) => {
  return httpUtil.post(`/map_v2/log/map/${viewId}/user_usage_map`, params);
};
export const API_collaborateApply = (viewId,param) => {
  return httpUtil.post(`/map_v2/msg/map/${viewId}/collaborate/apply`, param);
};

