import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Input, message, Tooltip, Tag } from 'antd';
import toolbarStyle from '@/style/components/main.toolbar.less';

export default class BusinessTool extends React.PureComponent {
	state = {

	};

	componentDidMount() {
	}

	render() {
		let me = this;
		const { contentWrapSpacing } = this.props;
		// 按钮样式
		const btnOption = {
			size: 'default',
		};

		return (
			<div className={this.props.className + ' ' + toolbarStyle['tabs-frame'] + ' dark-theme'}>
				<div className={toolbarStyle['tabs-wrap']} style={{paddingRight: contentWrapSpacing - 12}}>
					{
						this.props.buttons.length > 0 && this.props.buttons.map((btn, index) => (
							<Button
								{...btnOption}
								className={btn.active ? 'active' : (btn.module=='dataset' ? toolbarStyle['new']:undefined)}
								style={{borderBottom: btn.active ? '3px solid #1890ff' : '3px solid transparent'}}
								key={`index_${btn.name}_${index}`}
								onClick={btn.onClick}
							>
								{btn.name}
								{btn.type ? <Icon type={btn.icon} theme="outlined"/> : null}
							</Button>
						))
					}
				</div>
			</div>
		);
	}
}
BusinessTool.defaultProps = {
	buttons: [],
	divClassName: '',
	contentWrapSpacing: 100,
};

BusinessTool.propTypes = {
	buttons: PropTypes.array.isRequired,
	divClassName: PropTypes.string,
	history: PropTypes.object, // router的参数
	module: PropTypes.string,
	refresh: PropTypes.func,
	contentWrapSpacing: PropTypes.number, // 计算左右间距
	showCreateViewModal: PropTypes.func, // 显示新建图谱弹框
};
